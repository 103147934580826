/** initialize */
document.addEventListener('DOMContentLoaded', function () {
  // burger
  //burger()
  // pagetop
  pagetop()
  // accordion
  accordion()
  // tab
  tab();
  // ページ内リンク
  pageAnchor();
})



/**
***  burger  */
// const burger = () => {
//   const burger = document.getElementById( 'js-burger' )
//   const openMenuClass = 'open-menu'

//   if ( burger != null ) {
//     burger.addEventListener('click', function () {
//       document.body.classList.toggle(openMenuClass)
//     })
//   }
// }



/**
***  pagetop  */
const pagetop = () => {
  // const pagetop = document.getElementById('js-pageTop')
  // const pagetopActiveClass = 'active'

  // if ( pagetop != null ) {
  //   window.addEventListener('scroll', function (e) {
  //     if (window.scrollY > 100) {
  //       pagetop.classList.add(pagetopActiveClass)
  //     } else {
  //       pagetop.classList.remove(pagetopActiveClass)
  //     }
  //   })
  //   pagetop.addEventListener('click', function () {
  //     window.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth'
  //     })
  //   })
  // }

  const pagetop = $('#js-pageTop');
  // 100px スクロールしたらボタン表示
  $(window).scroll(function () {
     if ($(this).scrollTop() > 100) {
        pagetop.addClass('active');
     } else {
        pagetop.removeClass('active');
     }
  });
  pagetop.click(function () {
     $('body, html').animate({ scrollTop: 0 }, 500);
     return false;
  });
}



/**
***  accordion  */
const accordion = () => {
  // Cross-browser way to get the computed height of a certain element.
  const getStyle = (el, styleProp) => {
    let value, defaultView = ( el.ownerDocument || document ).defaultView;
    // W3C standard way:
    if ( defaultView && defaultView.getComputedStyle ) {
      // sanitize property name to css notation
      // (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } else if ( el.currentStyle ) { // IE
      // sanitize property name to camelCase
      styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
        return letter.toUpperCase();
      });
      value = el.currentStyle[styleProp];
      // convert other units to pixels on IE
      if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
        return (function(value) {
          var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
          el.runtimeStyle.left = el.currentStyle.left;
          el.style.left = value || 0;
          value = el.style.pixelLeft + "px";
          el.style.left = oldLeft;
          el.runtimeStyle.left = oldRsLeft;
          return value;
        })(value);
      }
      return value;
    }
  }
  // アコーディオン処理
  const acc = document.getElementsByClassName('js-accordion-trigger');
  let i;
  // ページを開いた時にアコーディオンを開いた状態にしたいときの処理
  //const firstAccordion = acc[0];
  //const firstPanel = firstAccordion.nextElementSibling;
  //firstAccordion.classList.add('active');
  //firstPanel.style.maxHeight = firstPanel.scrollHeight + 'px';

  // Add onclick listener to every accordion element
  for ( i = 0; i < acc.length; i++ ) {
    acc[i].addEventListener('click', function () {
      // For toggling purposes detect if the clicked section is already 'active'
      const isActive = this.classList.contains('active');
      // Close all accordions
      // const allAccordions = document.getElementsByClassName('js-accordion-trigger');
      // for ( let j = 0; j < allAccordions.length; j++) {
      //   // Remove active class from section header
      //   allAccordions[j].classList.remove('active');
      //   // Remove the max-height class from the panel to close it
      //   const panel = allAccordions[j].nextElementSibling;
      //   const maxHeightValue = getStyle(panel, 'maxHeight');

      //   if (maxHeightValue !== '0px') {
      //     panel.style.maxHeight = null;
      //   }
      // }
      // Toggle the clicked section using a ternary operator
      isActive ? this.classList.remove('active') : this.classList.add('active');
      // Toggle the panel element
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    })
  }
}



/**
***  tab  */
// const tab = () => {
//   const tabs = document.getElementsByClassName('js-tab')
//   const allContents = document.getElementsByClassName('js-contents')
//   const currentClassName = 'current'
//   const currentViewClassName = 'current-view'
//   //
//   for (let i = 0; i < tabs.length; i++){
//     tabs[i].addEventListener('click', function () {

//       // データ属性を取得する
//       const view = this.dataset.view
//       // .current が付与しているかチェック
//       const isCurrent = this.classList.contains(currentClassName)

//       if (!isCurrent) {
//         // remove .current
//         for ( let j = 0; j < tabs.length; j++ ){
//           tabs[j].classList.remove(currentClassName)
//         }
//         this.classList.add(currentClassName)
//       }

//       // remove .current-view
//       for ( let k = 0; k < allContents.length; k++ ){
//         allContents[k].classList.remove(currentViewClassName)
//       }
//       //
//       document.getElementById(view).classList.add(currentViewClassName)
//     })
//   }
// }


const tab = () => {
  // タブ
  const tabs     = '.tab';
  const label   = $(tabs).find('.tab-label');
  const content = $(tabs).find('.tab-content');
  const tag     = label.find('.label-item');
  const current = 'current';
  //
  showTabContent();
  //
  tag.on('click', function(e){
    const self = $(this);
    e.preventDefault();
    label.find('li').removeClass(current);
    self.parent().addClass(current);
    showTabContent();
  });
  //
  function showTabContent(){
    const id = $( '.' + current ).find('.label-item').attr('data-label');
    content.find('>*').hide();
    $('#' + id).fadeIn();
  }
}
  // タブ




// ページ内リンク
const pageAnchor = function(){
  // const elm = document.querySelectorAll('.js-page-anchor');

  // // ない場合はなにもしない
  // if( ! elm.length ){ return; };

  // // イベント
  // for ( let i = 0; i < elm.length; i++) {
  //   elm[i].addEventListener( 'click', function(e){
  //     e.preventDefault();
  //     const href = this.getAttribute('href');
  //     // ページ内リンクでない場合は何もしない
  //     if( href.slice(0, 1) != '#' ){
  //       return;
  //     };
  //     // リンク先の要素を取得
  //     const pageID = document.getElementById(href.slice(1));
  //     if( !pageID ){ return; };
  //     const rect = pageID.getBoundingClientRect();
  //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //     const position = rect.top + scrollTop;

  //     //スクロール処理
  //     window.scrollTo({
  //       top: position - 16,
  //       left: 0,
  //       behavior: 'smooth'
  //     });
      
  //   });
  // }
  $('a[href^="#"]').click(function(){
    var w = window.innerWidth;
    if(w >= 980){
      var height = 57;
    }else{
      var height = 0;
    }
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - height;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
};

//アンカーリンクのズレ対応
$(document).ready(function(){
  var hash = location.hash;
  scrollAction( hash );
});
$(window).on('load', function() {
  var hash = location.hash;
  scrollAction( hash );
});

function scrollAction( id ){
  var w = window.innerWidth;
  if(w >= 980){
    var height = 57;
  }else{
    var height = 0;
  }
  var speed = 0;
  var target = $(id == "#" || id == "" ? 'html' : id);
  var position = target.offset().top - height;
  $("html, body").animate({scrollTop:position}, speed, "swing");
  return false;
}
